import React from "react";
import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledProvider } from "styled-components";
import Home from "./pages/Home/Home";
import { LoadScript } from "@react-google-maps/api";
import UserProjectsMap from "./pages/UserProjectsMap/UserProjectsMap";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import CompanyProjectsMap from "./pages/UserProjectsMap/CompanyProjectsMap";
import PartnersLandingPage from "./pages/PartnersLandingPage/PartnersLandingPage";

const LIBRARIES = ["places", "geometry"];

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#2faf4d",
      contrastText: "#fff",
    },
  },
});

const styledTheme = {
  main: "#2faf4d",
  darkMain: "#2c8541",
};

const App = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <StyledProvider theme={styledTheme}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          libraries={LIBRARIES}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path=":id/projects" element={<UserProjectsMap />} />
            <Route
              path="/company/:id/projects"
              element={<CompanyProjectsMap />}
            />
            <Route path="/partners/:id" element={<PartnersLandingPage />} />
          </Routes>
        </LoadScript>
      </StyledProvider>
    </ThemeProvider>
  );
};

export default App;
